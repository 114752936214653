import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import { Alert, Card } from 'tunggal';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Panel = makeShortcode("Panel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Alert`}</inlineCode>{` Merupakan sebuah pesan yang bersifat interupsi muncul sebagai respon dari interaksi yang dilakukan user, biasanya memberitahukan user mengenai informasi berkenaan dengan tindakan yang mereka ambil.`}</p>
    </PageDescription>
    <h2>{`Rules of use`}</h2>
    <p>{`Gunakan alert ketika:`}</p>
    <ul>
      <li parentName="ul">{`Validasi dari sistem.`}</li>
      <li parentName="ul">{`Memberikan notifikasi sistem`}</li>
      <li parentName="ul">{`Memberitahu user masalah yang telah terjadi atau sudah terjadi (berhubungan dengan tindakan yang diambil)`}</li>
    </ul>
    <h2>{`Contextual`}</h2>
    <p>{`Use alert according to the context`}</p>
    <Card bordered mdxType="Card">
  <Alert color="primary" onClose={function noRefCheck() {}} mdxType="Alert">
    This is alert for the basic information
  </Alert>
      <br />
      <Alert color="approval" onClose={function noRefCheck() {}} mdxType="Alert">
  This is alert for success or positive information
      </Alert>
      <br />
      <Alert color="secondary" onClose={function noRefCheck() {}} mdxType="Alert">
  This is alert for warning information
      </Alert>
      <br />
  <Alert color="danger" onClose={function noRefCheck() {}} mdxType="Alert">
    This is alert for danger information
  </Alert>
    </Card>
    <h2>{`Examples`}</h2>
    <h3>{`Simple`}</h3>
    <p>{`Use simple alerts as information to the user in one sentence`}</p>
    <Card bordered mdxType="Card">
  <Alert color="primary" onClose={function noRefCheck() {}} mdxType="Alert">
    Your order was successful, please confirm your payment immediately
  </Alert>
    </Card>
    <h3>{`Basic`}</h3>
    <p>{`Use basic alerts as information to the user with the context written in the title`}</p>
    <Card bordered mdxType="Card">
  <Alert color="primary" onClose={function noRefCheck() {}} title="Perhatian" mdxType="Alert">
    Segera lakukan konfirmasi pembayaran secepatnya (maksimal 12jam)
  </Alert>
    </Card>
    <h3>{`Dismissable`}</h3>
    <p>{`Use dismissable alerts as short information for users that can disappear by themselves or dismissed by the user`}</p>
    <Card bordered mdxType="Card">
  <Alert color="primary" onClose={function noRefCheck() {}} showClose mdxType="Alert">
    Segera lakukan konfirmasi pembayaran secepatnya (maksimal 12jam)
  </Alert>
    </Card>
    <h3>{`Attention`}</h3>
    <p>{`Use attention alert with additional icons to grab the user’s attention so that information can be conveyed`}</p>
    <Card bordered mdxType="Card">
  <Alert color="secondary" icon="heart" onClose={function noRefCheck() {}} title="I Love You" mdxType="Alert">
    Kamu adalah pelanggan setia kami, aku ingin kamu terus bersama kami
  </Alert>
    </Card>
    <h3>{`Simple attention`}</h3>
    <p>{`Use simple attention alert with additional icons to grab the user’s attention so that information can be conveyed`}</p>
    <Card bordered mdxType="Card">
  <Alert color="secondary" icon="heart" onClose={function noRefCheck() {}} mdxType="Alert">
    Kamu adalah pelanggan setia kami, aku ingin kamu terus bersama kami
  </Alert>
    </Card>
    <h3>{`Full width`}</h3>
    <p>{`Use full width alert with information displayed on a card, modal box or sheet box`}</p>
    <Panel mdxType="Panel">
  <Alert color="secondary" icon="heart" onClose={function noRefCheck() {}} title="I Love You" fullWidth mdxType="Alert">
    Kamu adalah pelanggan setia kami, aku ingin kamu terus bersama kami
  </Alert>
    </Panel>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      